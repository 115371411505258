define("labs-zap-search/helpers/lookup-community-district", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookupCommunityDistrict = lookupCommunityDistrict;
  _exports.default = _exports.communityDistrictLookup = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var communityDistrictLookup = [['K01', '1', 'Brooklyn'], ['K02', '2', 'Brooklyn'], ['K03', '3', 'Brooklyn'], ['K04', '4', 'Brooklyn'], ['K05', '5', 'Brooklyn'], ['K06', '6', 'Brooklyn'], ['K07', '7', 'Brooklyn'], ['K08', '8', 'Brooklyn'], ['K09', '9', 'Brooklyn'], ['K10', '10', 'Brooklyn'], ['K11', '11', 'Brooklyn'], ['K12', '12', 'Brooklyn'], ['K13', '13', 'Brooklyn'], ['K14', '14', 'Brooklyn'], ['K15', '15', 'Brooklyn'], ['K16', '16', 'Brooklyn'], ['K17', '17', 'Brooklyn'], ['K18', '18', 'Brooklyn'], ['X01', '1', 'Bronx'], ['X02', '2', 'Bronx'], ['X03', '3', 'Bronx'], ['X04', '4', 'Bronx'], ['X05', '5', 'Bronx'], ['X06', '6', 'Bronx'], ['X07', '7', 'Bronx'], ['X08', '8', 'Bronx'], ['X09', '9', 'Bronx'], ['X10', '10', 'Bronx'], ['X11', '11', 'Bronx'], ['X12', '12', 'Bronx'], ['M01', '1', 'Manhattan'], ['M02', '2', 'Manhattan'], ['M03', '3', 'Manhattan'], ['M04', '4', 'Manhattan'], ['M05', '5', 'Manhattan'], ['M06', '6', 'Manhattan'], ['M07', '7', 'Manhattan'], ['M08', '8', 'Manhattan'], ['M09', '9', 'Manhattan'], ['M10', '10', 'Manhattan'], ['M11', '11', 'Manhattan'], ['M12', '12', 'Manhattan'], ['Q01', '1', 'Queens'], ['Q02', '2', 'Queens'], ['Q03', '3', 'Queens'], ['Q04', '4', 'Queens'], ['Q05', '5', 'Queens'], ['Q06', '6', 'Queens'], ['Q07', '7', 'Queens'], ['Q08', '8', 'Queens'], ['Q09', '9', 'Queens'], ['Q10', '10', 'Queens'], ['Q11', '11', 'Queens'], ['Q12', '12', 'Queens'], ['Q13', '13', 'Queens'], ['Q14', '14', 'Queens'], ['R01', '1', 'Staten Island'], ['R02', '2', 'Staten Island'], ['R03', '3', 'Staten Island']].map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        code = _ref2[0],
        num = _ref2[1],
        boro = _ref2[2];

    return {
      code: code,
      num: num,
      boro: boro,
      searchField: "".concat(boro, " ").concat(num)
    };
  });
  _exports.communityDistrictLookup = communityDistrictLookup;

  function lookupCommunityDistrict() {
    return communityDistrictLookup;
  }

  var _default = Ember.Helper.helper(lookupCommunityDistrict);

  _exports.default = _default;
});